/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Text, ContactForm, YouTube, Subtitle } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Pricelist - Villa Club Tropicana Seychelles"}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="css-1lcumdb --style2 --full --parallax pb--60 pt--60" name={"uvod"} parallax={true} fullscreen={true} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/36311/7b73a03336b54893adbd1b1941382b41_s=350x_.jpeg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/36311/7b73a03336b54893adbd1b1941382b41_s=660x_.jpeg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/36311/7b73a03336b54893adbd1b1941382b41_s=860x_.jpeg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/36311/7b73a03336b54893adbd1b1941382b41_s=1400x_.jpeg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/36311/7b73a03336b54893adbd1b1941382b41_s=2000x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/36311/7b73a03336b54893adbd1b1941382b41_s=660x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/36311/7b73a03336b54893adbd1b1941382b41_s=1400x_.jpeg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/36311/7b73a03336b54893adbd1b1941382b41_s=3000x_.jpeg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper className="mt--20">
              
              <Title className="title-box fs--72 title-box--invert" content={"PRiCiNG<br>"}>
              </Title>

              <Text className="text-box fs--22" style={{"maxWidth":480}} content={"<span style=\"caret-color: rgb(255, 255, 255); color: var(--white);\">The price is individually tailored depending on duration of stay, season, number of guests and whether you are renting &nbsp;one or both apartments.<br><br><span style=\"font-weight: bold;\">PLEASE CONTACT US FOR A CUSTOMiSED QUOTATiON.</span><br><br></span><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"rvdkuk56qs9"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"<span style=\"color: var(--color-custom-2); font-weight: bold;\">PLEASE CONTACT US FOR A CUSTOMiSED QUOTATiON</span>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <ContactForm className="--shape2 --style1" style={{"maxWidth":740}} action={"/contact"} fields={[{"name":"FULL NAME","type":"text","required":true,"placeholder":"FULL NAME"},{"name":"E-MAIL","type":"email","required":true,"placeholder":"E-MAIL"},{"name":"PHONE NUMBER","type":"text","placeholder":"PHONE NUMBER"},{"name":"MESSAGE","type":"textarea","required":true,"placeholder":"MESSAGE"},{"name":"SEND","type":"submit","align":"center"}]}>
              </ContactForm>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"tjawbvzgf9"}>
          
          <ColumnWrap className="column__flex --center el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"<span style=\"color: var(--color-custom-2--15);\">ViSTA APARTMENT<br>from 299 EUR/night</span><span style=\"color: var(--color-custom-2);\"><br></span>"}>
              </Title>

              <Text className="text-box" content={"<span style=\"color: var(--color-custom-2--15);\">A luxurious 150m2 apartment featuring two ample sized ensuite air-conditioned bedrooms with a</span> <span style=\"color: var(--color-custom-2--15);\">fully equipped open plan kitchen/ living /dining area (with a sofa bed) and 55-inch Smart TV. Each bedroom has its own closet. All rooms open out onto a very generous terrace with 4 sun loungers offering incredible views and leading to the infinity swimming pool. This apartment sleeps 4 to 5 (with sofa bed). Washing machine is provided as is all bedlinen and towels.</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <YouTube loop={false} embedId={"63Cm2YBf_7s"} autoplay={false}>
              </YouTube>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"fux9l0iudk"}>
          
          <ColumnWrap className="column__flex --center el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"<span style=\"color: var(--color-custom-2--15);\">GRANiTE APARTMENT<br>from 199 EUR/night</span><span style=\"color: var(--color-custom-2);\"><br></span>"}>
              </Title>

              <Text className="text-box" content={"<span style=\"color: var(--color-custom-2--15);\">90m2 situated on the lower level, this is a very spacious and airy, fully equipped air-conditioned apartment with an open space kitchen/dining/living area, 55-inch Smart TV and one large air-conditioned ensuite bedroom. There is a double bed and bunk bed. It sleeps anything from 2 to 6 (with sofa bed). Boasting two giant granite boulders integrated into the open living space and bedroom, it is a one-of-a-kind gem. It also has a terrace offering lovely view to relax and unwind. The infinity swimming pool is on the upper level with separate access.<br>This apartment has access to the laundry. All bedlinen and towels are provided.</span><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <YouTube embedId={"fgmJ__8XSsg"} autoplay={false}>
              </YouTube>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--parallax pb--60 pt--60" name={"[[UNIsecntioname12]]-1"} style={{"backgroundColor":"rgba(142,173,240,1)"}} parallax={true} css={css`
      background-position: 50% 50%;
    `}>
          
          <ColumnWrap className="column__flex js-anim  --anim4 --anim-s3 --center el--1 flex--center" anim={"4"} animS={"3"} style={{"backgroundColor":"var(--color-custom-2--15)"}} columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":960}}>
              
              <Title className="title-box fs--62" content={"<span style=\"caret-color: rgb(93, 137, 233); font-weight: bold; color: var(--white);\">&nbsp;ViSTA + GRANiTE APARTMENTS</span><br>"}>
              </Title>

              <Subtitle className="subtitle-box" content={"<span style=\"font-weight: bold; color: var(--white);\">from 399 EUR/night</span>"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":""}} content={"<span style=\"color: var(--white);\">This price applies when both of the above described apartments are rented as a whole. This set up is ideal for a larger family or a group of friends or for 2 families wishing to vacation together.</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"paticka"} style={{"backgroundColor":"var(--color-blend--95)"}}>
          
          <ColumnWrap className="column__flex --left el--2" anim={""} animS={"3"} columns={"2"}>
            
            <ColumnWrapper style={{"maxWidth":449}}>
              
              <Subtitle className="subtitle-box subtitle-box--center" content={"<span style=\"color: var(--color-custom-2);\">VILLA CLUB TROPICANA CARANA<br>SEYCHELLES<br></span>"}>
              </Subtitle>

              <Text className="text-box text-box--center" content={"<span style=\"color: var(--color-custom-2--15);\">Holiday self-catering rental. Your unique opportunity to enjoy Seychelles in true `island style.`</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--right" style={{"maxWidth":""}}>
              
              <Subtitle className="subtitle-box lh--16" content={"<span style=\"color: var(--color-custom-2--15);\">Upper Carana, Glacis District, Mahé, Seychelles<br>Business Registration number: B8435640<br>Licence number: 324496<br>&nbsp;info@villaclubtropicana.com<br>&nbsp;+420 737466348</span><br>"}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}